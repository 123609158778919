import { useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../Theme";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalance";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PetsIcon from "@mui/icons-material/Pets";
import SpaIcon from "@mui/icons-material/Spa";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useDispatch, useSelector } from "react-redux";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Terrain from "@mui/icons-material/Terrain";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { useQuery } from "react-query";
import { Environment } from "../Environment";
import { useEffect } from "react";
import { notify } from "../utils/Toast";
import axios from "axios";
import LockIcon from "@mui/icons-material/Lock";
import { loadState, saveState } from "../utils/LocalStorage";
import { login } from "../stores/UserSlice";
import TollIcon from "@mui/icons-material/Toll";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import NotesIcon from "@mui/icons-material/Notes";
import PestControlIcon from "@mui/icons-material/PestControl";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      icon={icon}
      component={<Link to={to} />}
      onClick={() => setSelected(title)}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MySidebar = () => {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [collapsed, setCollapsed] = useState(false);
  const [selected, setSelected] = useState("dashboard");
  const [employee, setEmployee] = useState(false);
  const isDesktop = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      //fetch employee of user
      fetchEmployee.refetch();
    }
  }, [user]);

  useEffect(() => {
    if (!isDesktop) {
      setCollapsed(true);
    }
  }, [isDesktop]);

  const fetchEmployee = useQuery("Fetch employee", () => {
    return axios
      .get(
        `${Environment.BaseURL}/api/employees/readAllEmployee?User_ID=${user.id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(
        (res) => {
          console.log(res.data);
          if (res.status === 200) {
            if (res.data.length > 0) {
              setEmployee(res.data[0]);
              const userN = {
                ...user,
                loggedIn: true,
                otpVerified: true,
                employee: res.data[0],
              };
              const usr = loadState("auth");
              if (usr) {
                saveState("auth", userN);
              }
              dispatch(login(userN));
            }
          }
        },
        (err) => {
          if (err.response.status !== 403) {
            notify("Could not fetch data");
            console.error(err);
          }
        }
      );
  });
  return (
    <Box m="0">
      <Sidebar
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            background: `${colors.primary[400]}`,
          },
          [`.${menuClasses.icon}`]: {
            backgroundColor: "transparent",
          },
        }}
        defaultCollapsed={collapsed}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: disabled
                    ? "#f5d9ff"
                    : active
                    ? "#ffffff"
                    : colors.greenAccent[100],
                  backgroundColor: active
                    ? colors.greenAccent[500]
                    : colors.primary[400],
                  "&:hover": {
                    backgroundColor: colors.greenAccent[500],
                    color: "#ffffff",
                  },
                };
            },
          }}
        >
          <MenuItem
            icon={collapsed ? <MenuOutlinedIcon /> : undefined}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  PORTAL
                </Typography>
                <IconButton onClick={() => setCollapsed(!collapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profileUser"
                  width="100px"
                  height="100px"
                  src={
                    user.role === "institution"
                      ? user.institution.logo
                      : user.User_Passport_Photo
                      ? user.User_Passport_Photo
                      : `../../assets/images/thorium-main.png`
                  }
                  style={{
                    cursor: "pointer",
                    objectPosition: "center",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user.role === "institution"
                    ? user.institution.name
                    : user.name}
                </Typography>
                <Typography variant="h5" color={colors.grey[500]}>
                  {user.role === "institution"
                    ? user.institution.bio
                    : user.role}
                </Typography>
              </Box>
            </Box>
          )}
          {/* Menu items */}
          <Box>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Management
            </Typography>
            {((employee && employee.User_Management) || !employee) && (
              <Item
                title={user.role === "admin" ? "Users" : "Farmers"}
                to="/users"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Institution_Management) ||
              user.role === "admin") && (
              <Item
                title="Institutions"
                to="/institutions"
                icon={<AccountBalanceOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Farm_Management) || !employee) && (
              <Item
                title="Farms"
                to="/farms"
                icon={<AgricultureIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Institution_Management) ||
              user.role === "admin") && (
              <Item
                title="Carbon Credits"
                to="/carbonList"
                icon={<LibraryBooksIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {user.role === "admin" && (
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Data
              </Typography>
            )}
            {user.role === "admin" && (
              <Item
                title={"Banners"}
                to="/banners/view"
                icon={<ViewCarouselIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Farm_Management) ||
              user.role === "admin") && (
              <Item
                title="Animals"
                to="/animals/view"
                icon={<PetsIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Farm_Management) ||
              user.role === "admin") && (
              <Item
                title="Crops"
                to="/crops/view"
                icon={<SpaIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Farm_Management) ||
              user.role === "admin") && (
              <Item
                title="Crop Calendar"
                to="/cropCalendar/view"
                icon={<CalendarMonthIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Farm_Management) ||
              user.role === "admin") && (
              <Item
                title="Writeups"
                to="/writeups/view"
                icon={<NotesIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Farm_Management) ||
              user.role === "admin") && (
              <Item
                title="Pests & Diseases"
                to="/pestdisease/view"
                icon={<PestControlIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {user.role === "admin" && (
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Marketplace
              </Typography>
            )}
            {((employee && employee.Marketplace_Management) ||
              user.role === "admin") && (
              <Item
                title="Products"
                to="/products"
                icon={<ShoppingBagIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Marketplace_Management) ||
              user.role === "admin") && (
              <Item
                title="Orders"
                to="/orders/view"
                icon={<LocalShippingIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Marketplace_Management) ||
              user.role === "admin") && (
              <Item
                title="Product Categories"
                to="/categories/view"
                icon={<AccountTreeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {user.role === "admin" && (
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Sales
              </Typography>
            )}
            {((employee && employee.Sales_Management) ||
              user.role === "admin") && (
              <Item
                title="Farm Payments"
                to="/payments/farm"
                icon={<Terrain />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {((employee && employee.Sales_Management) ||
              user.role === "admin") && (
              <Item
                title="Marketplace Payments"
                to="/payments/marketplace"
                icon={<LocalGroceryStoreIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((employee && employee.Sales_Management) ||
              user.role === "admin" ||
              user.role === "institution") && (
              <Item
                title={
                  user.role === "institution"
                    ? "Farmer Disbursements"
                    : "Institution Disbursements"
                }
                to="/institutions/disbursement/view"
                icon={<RequestQuoteIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {user.role === "institution" &&
              ((employee && employee.Sales_Management) || !employee) && (
                <Item
                  title={"Farm Subscriptions"}
                  to="/payments/farmsubscriptions"
                  icon={<TollIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            {user.role === "admin" && (
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Other
              </Typography>
            )}
            {(user.role === "admin" || user.role === "institution") && (
              <Item
                title="Employees"
                to="/employees/view"
                icon={<LockOpenIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {user.role === "admin" && (
              <Item
                title="Roles"
                to="/roles"
                icon={<LockIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MySidebar;
